import React from 'react';
import { useTranslation } from 'react-i18next';
import { RenderMenuList } from 'components/MenuList';
import { ROUTES } from 'constant';
import { ADMIN, BASE_USER, CONTRIBUTOR, IMenuList, REDACTOR, SUPER_ADMIN } from 'interfaces';
import { BsPlusCircle } from 'react-icons/bs';
import defaultTheme from '../../../assets/styles';
import { StyledMainMenu } from './Header.style';
import { FiEye } from 'react-icons/fi';

export const MainMenu = () => {
  const { t } = useTranslation();
  const getMenuList = () => {
    const menu: IMenuList[] = [
      {
        icon: <BsPlusCircle size="1em" color={defaultTheme.primary[0]} />,
        key: 'CREATE_EVENT',
        exact: true,
        to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.MY_EVENTS}${ROUTES.PROFILE.CREATE_EVENT}`,
        permissions: [BASE_USER, CONTRIBUTOR, REDACTOR, ADMIN, SUPER_ADMIN],
        title: t('Create event'),
      },
    ];
    if (process.env.REACT_APP_EXTERNAL_EVENTS_LINK) {
      menu.push({
        icon: <FiEye size="1em" color={defaultTheme.primary[0]} />,
        key: 'VIEW_EVENTS',
        exact: true,
        to: { external_uri: process.env.REACT_APP_EXTERNAL_EVENTS_LINK },
        title: t('View events'),
      });
    }
    return menu.reverse();
  };

  return <StyledMainMenu>{RenderMenuList(getMenuList())}</StyledMainMenu>;
};

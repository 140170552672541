import React from 'react';
import LogoArea from './Logo.style';
import defaultTheme from '../../assets/styles';
import { FaUserCircle } from 'react-icons/fa';
import { ProfileAvatar } from '../Navbar/Navbar.style';

interface ILogo {
  className?: string;
  withLink: boolean;
  src?: string;
  title?: string;
}

export const Logo = ({ className, withLink, src }: ILogo): JSX.Element => (
  <LogoArea className={className}>
    {withLink ? (
      <a href={process.env.REACT_APP_LOGO_URL}>{src && <img loading="lazy" src={src} alt="Logo" />}</a>
    ) : (
      <ProfileAvatar>
        {src ? <img loading="lazy" src={src} alt="Logo" /> : <FaUserCircle color={defaultTheme.primary[0]} />}
      </ProfileAvatar>
    )}
  </LogoArea>
);

import styled, { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Select, { StylesConfig } from 'react-select';

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &::before,
        &::after {
            box-sizing: inherit;
        }

        ::-webkit-scrollbar {
            background: transparent;

            :vertical {
                width: 5px;
            }

            :horizontal {
                height: 5px;
            }
        }

        ::-webkit-scrollbar-thumb {
            background: ${themeGet('primary.0')};
            width: 5px;
            border-radius: 5px;
        }
    }

    ::selection {
        background: ${themeGet('primary.0', '#EE3932')};
        color: ${themeGet('color.1', '#ffffff')};
    }

    ::placeholder {
        color: #666666 !important;
    }

    html {
        box-sizing: border-box;
        -ms-overflow-style: scrollbar;
    }

    html,
    html a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a,
    p,
    li,
    dl,
    th,
    dt,
    input,
    textarea,
    span,
    div, button {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        -webkit-tap-highlight-color: transparent;
    }

    h1, h2, h3 {
        font-family: ${themeGet('fonts.0')};
    }

    body {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        font-family: ${themeGet('fonts.1')};
        -webkit-tap-highlight-color: transparent;
    }

    body.noscroll {
        overflow: hidden;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;
    }

    a {
        text-decoration: none;
    }

    img {
        max-width: 100%;
        height: auto;

        &:focus {
            outline: none;
        }
    }

    #root {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        overflow: hidden;

        .with-background {
            background: ${themeGet('backgrounds.root', '#ffffff')};
        }

        main.ant-layout-content {
            flex: 1 0 auto;
        }
    }

    .ant-btn {
        span {
            line-height: 1.9;
        }
    }

    .ant-popover {
        &.profile_menu {
            z-index: 9999 !important;
        }

        .ant-popover-buttons {
            display: flex;
            justify-content: space-between;

            button {
                padding: 4px 15px;
            }
        }

        .ant-popover-content {
            .ant-popover-inner {
                .ant-popover-inner-content {
                    ul.ant-menu {
                        border: 0;

                        &.account_menu {
                            li {
                                color: ${themeGet('text.0', '#2C2C2C')};
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: 300;
                                height: auto;
                                transition: color 0.2s ease-in-out;

                                &.ant-menu-item-selected,
                                &.ant-menu-item-active {
                                    background-color: transparent;
                                }

                                a {
                                    padding: 8px 0;
                                    color: ${themeGet('text.0', '#2C2C2C')};
                                    transition: color 0.2s ease-in-out;

                                    &:hover {
                                        color: ${themeGet('primary.0', '#EE3932')};
                                    }

                                    &.active {
                                        font-weight: 700;
                                        color: ${themeGet('primary.0', '#EE3932')};
                                    }
                                }

                                button {
                                    border: 0;
                                    cursor: pointer;
                                    padding: 8px 0;
                                    background-color: transparent;
                                    transition: color 0.2s ease-in-out;

                                    &:hover {
                                        color: ${themeGet('primary.0', '#EE3932')};
                                    }

                                    &:focus {
                                        outline: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .DateRangePicker {
        .DateRangePickerInput {
            .DateRangePicker_picker {
                .DayPicker_weekHeader {
                    .DayPicker_weekHeader_ul {
                        .DayPicker_weekHeader_li {
                            color: ${themeGet('text.0', '#2C2C2C')};

                            small {
                                font-size: 13px;
                            }
                        }
                    }
                }

                .DayPicker_focusRegion {
                    .CalendarMonthGrid {
                        .CalendarMonth {
                            .CalendarMonth_caption {
                                font-size: 16px;
                                color: ${themeGet('text.0', '#2C2C2C')};
                            }
                        }
                    }
                }

                .DayPickerNavigation {
                    .DayPickerNavigation_button {
                        &:focus {
                            outline: none;
                        }
                    }
                }

                .DayPickerKeyboardShortcuts_buttonReset {
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }

    .ant-tooltip {
        font-size: 13px;
        font-weight: 700;
        z-index: 99999999;

        .ant-tooltip-inner {
            box-shadow: none;
            //text-align: center;
            border-radius: 3px;
            min-height: 26px;
            padding: 4px 10px;
        }
    }

    .ant-select-dropdown {
        .ant-select-dropdown-menu {
            .ant-select-dropdown-menu-item {
                padding: 12px 12px;
                line-height: 1;

                &.ant-select-dropdown-menu-item-active {
                    background-color: rgba(0, 132, 137, 0.1);
                }
            }
        }
    }

    .view_with__popup {
        &.room_guest__component {
            &.active {
                min-height: 54px;
            }

            &.alt {
                .popup_container {
                    #popup {
                        > div {
                            > div {
                                padding: 0;
                            }
                        }
                    }
                }

                &.active {
                    min-height: inherit;
                }
            }
        }
    }

    .ant-dropdown {
        &.social_share_menu {
            z-index: 9999 !important;

            .ant-menu {
                padding: 7px 0;
                min-width: 150px;
                border: 0;
                border-radius: 3px;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

                .ant-menu-item {
                    margin: 0;
                    padding: 0;
                    height: inherit;
                    line-height: inherit;

                    > div {
                        padding: 15px 20px;
                        color: ${themeGet('text.0', '#2C2C2C')};

                        svg {
                            margin-right: 8px;
                        }
                    }

                    &:hover {
                        background-color: ${themeGet('color.2', '#F7F7F7')};
                    }
                }
            }
        }
    }

    .ant-drawer,
    .ant-modal-mask,
    .ant-modal-wrap {
        z-index: 1001;
    }

    .ant-drawer {
        &.filter_drawer {
            padding: 0;
            pointer-events: none;

            &.ant-drawer-bottom {
                &.ant-drawer-open {
                    .ant-drawer-content-wrapper {
                        box-shadow: none;
                    }
                }
            }

            .ant-drawer-content-wrapper {
                pointer-events: all;

                .ant-drawer-wrapper-body {
                    height: 100%;

                    .ant-drawer-body {
                        height: 100%;
                        padding-top: 0;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    .ant-modal-wrap {
        .ant-modal-content {
            box-shadow: 0 1px 10px rgba(0, 0, 0, 0.16);
        }

        &.review_modal {
            .ant-modal {
                max-width: 1170px;
                box-sizing: border-box;
                @media only screen and (max-width: 1260px) {
                    padding-left: 30px;
                    padding-right: 30px;
                }
                @media only screen and (max-width: 767px) {
                    top: 0;
                    padding: 0;
                    margin-top: 0;
                    margin-bottom: 0;
                }

                .ant-modal-body {
                    padding: 30px;
                    @media only screen and (max-width: 1260px) {
                        padding: 30px;
                    }
                }

                .ant-modal-close {
                    line-height: 0;
                    width: fit-content;
                    height: fit-content;
                    position: absolute;
                    top: 18px;
                    right: 7px;
                }
            }

            .image_uploader {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                @media only screen and (max-width: 667px) {
                    flex-wrap: wrap;
                }

                .ant-upload {
                    @media only screen and (max-width: 667px) {
                        margin-bottom: 10px;
                    }

                    &.ant-upload-drag {
                        border: 0;
                        border-radius: 0;
                        background: transparent;

                        .ant-upload {
                            padding: 0;
                            display: block;
                            width: auto;
                            height: auto;
                        }
                    }

                    &.ant-upload-drag-hover {
                        .image-drag-area {
                            border-color: #48bdc1;
                        }
                    }
                }

                .ant-upload-list {
                    float: none;
                    display: flex;

                    .ant-upload-list-item {
                        width: 125px;
                        height: 125px;
                        border-radius: 3px;
                    }
                }

                .ant-upload-drag-container {
                    display: block;
                }
            }
        }
    }

    .gm-style-iw-c {
        box-shadow: none !important;
    }

    .gm-style-iw {
        padding: 0 !important;
        border-radius: 3px !important;
        min-width: fit-content !important;
        z-index: 1;

        .gm-style-iw-d {
            overflow-y: auto !important;
            overflow-x: hidden !important;
            max-width: 100% !important;
        }

        .info_window_card {
            width: 220px;
            margin-bottom: 0;

            > div {
                > img {
                    width: 100%;
                    height: 190px;
                    object-fit: cover;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            .content_wrapper {
                > div + div {
                    margin-top: 2px;
                    margin-bottom: 4px;
                }
            }
        }

        button[title="Close"].gm-ui-hover-effect {
            width: 26px !important;
            height: 26px !important;
            padding: 6px !important;
            border-radius: 50%;
            margin: 0 !important;
            top: 14px !important;
            right: 11px !important;
            opacity: 1;
            align-items: center;
            display: inline-flex !important;
            background-color: ${themeGet('color.1', '#ffffff')} !important;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

            > img {
                margin: 0 !important;
            }
        }
    }

    .ant-modal-wrap {
        &.image_gallery_modal {
            .ant-modal {
                top: 50px;

                .ant-modal-content {
                    box-shadow: none;
                    padding: 0 30px;
                    background: transparent;
                    @media only screen and (max-width: 480px) {
                        padding: 0 20px;
                    }

                    .ant-modal-body {
                        max-width: 1170px;
                        padding: 0;
                        margin: 0 auto;
                        @media only screen and (max-width: 1440px) {
                            position: relative;
                        }
                    }
                }

                .image_gallery_close {
                    background: transparent;
                    border: 0;
                    width: 60px;
                    height: 60px;
                    position: absolute;
                    top: -20px;
                    right: 40px;

                    &:focus,
                    &:hover {
                        outline: none;
                    }

                    @media only screen and (max-width: 1440px) {
                        top: 0;
                        right: 0;
                        svg {
                            path {
                                opacity: 0.8;
                                fill: ${themeGet('color.1', '#ffffff')};
                                transition: all 0.3s ease;
                            }
                        }

                        &:hover {
                            svg {
                                path {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .mobile-header {
        &.ant-drawer {
            z-index: 100000;
        }

        .ant-drawer-body {
            padding: 0;

            .auth-menu {
                border: 0;
                display: flex;
                padding: 25px 20px;
                align-items: center;
                background-color: ${themeGet('color.2', '#F7F7F7')};

                li {
                    height: auto;
                    line-height: 1;
                    padding: 0;
                    margin: 0;

                    &.ant-menu-item-selected,
                    &.ant-menu-item-active {
                        background-color: transparent;
                    }

                    a {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        padding: 12px 15px;
                        border-radius: 3px;
                        font-size: 12px;
                        font-weight: 700;
                        color: ${themeGet('text.0', '#2C2C2C')};
                    }

                    &:nth-child(2) {
                        a {
                            color: ${themeGet('color.1', '#ffffff')};
                            background-color: ${themeGet('primary.0', '#EE3932')};
                            transition: opacity 0.2s ease;

                            &:hover {
                                opacity: 0.9;
                            }
                        }
                    }
                }

                .close-btn {
                    margin-left: auto;
                }
            }

            .main-menu {
                border: 0;
                padding-top: 30px;

                li {
                    padding: 0;

                    &.ant-menu-item-selected,
                    &.ant-menu-item-active {
                        background-color: transparent;
                    }

                    a {
                        font-size: 14px;
                        padding: 0 31px;
                        border-left: 4px solid transparent;
                        color: ${themeGet('text.0', '#2C2C2C')};

                        &.active {
                            font-weight: 700;
                            border-color: ${themeGet('primary.0', '#EE3932')};
                            color: ${themeGet('primary.0', '#EE3932')};
                        }
                    }

                    button {
                        display: block;
                        text-align: left;
                        width: 100%;
                        border: 0;
                        padding: 0 32px;
                        cursor: pointer;
                        background-color: transparent;
                        color: ${themeGet('text.0', '#2C2C2C')};
                        transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

                        &:hover {
                            color: ${themeGet('primary.0', '#EE3932')};
                        }

                        &focus {
                            outline: 0;
                        }
                    }

                    &:hover {
                        a {
                            color: ${themeGet('primary.0', '#EE3932')};
                        }
                    }
                }
            }
        }
    }

    .pac-container {
        border: 0;
        z-index: 99999;
        padding: 5px 0;
        margin-top: 0;
        border-radius: 3px;
        box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
        @media only screen and (max-width: 991px) {
            margin-top: 0;
        }

        &::after {
            display: none;
        }

        .pac-item {
            border-top: 0;
            line-height: 30px;
            padding: 10px 14px;
            cursor: pointer;
            font-size: 13px;
            color: ${themeGet('text.0', '#2c2c2c')};

            .pac-icon {
                margin-top: 6px;
            }

            .pac-item-query {
                font-size: 14px;
                font-weight: 600;
                color: ${themeGet('text.0', '#2C2C2C')};

                .pac-matched {
                    font-weight: 700;
                    color: ${themeGet('color.0', '#000000')};
                    color: ${themeGet('color.0', '#000000')};
                }
            }

            &:hover {
                background-color: ${themeGet('color.2', '#F7F7F7')};
            }
        }
    }

    .grid_column .placeholder {
        max-width: 100%;
    }

    .ant-input-affix-wrapper {
        border-radius: 3px;
        border-color: ${themeGet('border.3', '#E6E6E6')};
        height: 48px;

        > input.ant-input {
            max-height: 39px;
            min-height: 29px;
        }

        &:hover,
        &:focus {
            border-color: ${themeGet('border.3', '#E6E6E6')};
        }
    }

    .ant-input-search-icon::before {
        border-color: ${themeGet('border.3', '#E6E6E6')};
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-checked::after {
        border-color: ${themeGet('primary.0', '#EE3932')} !important;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: rgba(0, 132, 137, 0.2);
    }

    .ant-col {
        width: 100%;
        @media only screen and (max-width: 480px) {
            flex-basis: 100%;
        }
    }

    .ant-card {
        border-radius: 10px;
        cursor: pointer;

        .ant-card-body {
            display: flex;
            align-items: center;
            justify-items: center;

            p {
                display: inline-block;
                margin: 0;
            }
        }

        @media only screen and (max-width: 480px) {
            width: 100%;
            margin: 0 0 10px;
        }

        &-meta {
            display: flex;
            align-items: center;

            &-title {
                white-space: normal;
            }
        }

        &:hover {
            box-shadow: 0 6px 12px ${themeGet('boxShadow.2', 'rgba(0, 0, 0, 0.16)')};
        }
    }

    .ant-divider-horizontal.ant-divider-with-text::before,
    .ant-divider-horizontal.ant-divider-with-text::after {
        position: static;
    }

    div.ant-row.single-row {
        height: 3rem;
        align-items: center;
    }

    .ant-spin {
        padding: 50%;
    }

    div.container {
        padding: 0.5rem 2.5rem;
    }

    &.events {
        width: 100%;

        :hover {
            box-shadow: none;
            cursor: default;
        }

        .ant-card-body {
            overflow-y: scroll;
        }

        .event_photo {
            width: 150px;
            height: 100px;
            object-fit: cover;
        }
    }

    .ant-tabs-nav-list {
        width: 100%;

        .ant-tabs-tab {
            width: 50%;
            display: flex;
            justify-content: center;
        }
    }

    .ant-upload-select {
        width: 100%;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .has-error {
        .select-wrapper, .ant-btn, .ant-input {
            border-color: #F9503D;
        }
    }

    .select-wrapper {
        > div[class^='-control'] {
            height: 48px;
            border-color: #e6e6e6;
            border-radius: 3px;
            overflow-y: scroll;

            :active, :focus, :focus-within {
                box-shadow: 0 0 0 1px ${themeGet('boxShadow.2')};
            }
        }

        &.has-error {
            [class$='-control'],
            .bvQLaa {
                border-color: #F9503D !important;
            }
        }

        [class*='IndicatorsContainer'] {
            max-height: 48px;
            align-self: normal;
        }

        [class$='-control'] {
            border-color: #e6e6e6;
            height: 48px;
            border-radius: 3px;
            overflow: scroll;

            ::-webkit-scrollbar {
                :horizontal {
                    height: 0;
                }
            }

            &:hover, &:active, &:focus, &:focus-within {
                border-color: #e6e6e6;
                box-shadow: 0 0 0 1px ${themeGet('boxShadow.2')};
            }
        }

        &:hover {
            border-color: #e6e6e6;
        }

        &:focus {
            outline: none;
        }
    }

    .ant-menu-item:active {
        background: transparent;
    }

    .ant-modal-title {
        font-weight: bold;
    }

    .ant-modal-footer {
        display: flex;
        justify-content: end;

        .cancel-btn {
            border-color: ${themeGet('primary.0')};
            background: ${themeGet('primary.0')};
            color: ${themeGet('color.1')};

            &:hover {
                background: ${themeGet('primary.1')};
                border-color: ${themeGet('primary.1')};
                color: ${themeGet('color.1')};
            }
        }

        .ant-btn {
            &[disabled] {
                background-color: #f5f5f5 !important;
                border-color: #d9d9d9;
            }
        }
    }

    .ant-card-meta-title {
        font-size: 14px;
        width: 100%;
    }

    .css-1okebmr-indicatorSeparator {
        display: none;
    }

    .ant-spin {
        transform: translateY(-50%);
        top: 50% !important;
        z-index: 99999999999 !important;
    }

    .ant-pagination {
        &-next,
        &-prev {
            &:hover {
                button {
                    border-color: ${themeGet('primary.0', '#EE3932')} !important;
                    color: ${themeGet('primary.0', '#EE3932')} !important;
                }
            }
        }

        &-disabled {
            &:hover {
                button {
                    color: rgba(0, 0, 0, 0.25) !important;
                    border-color: #d9d9d9 !important;
                }
            }
        }

        &-item {
            &-active {
                border-color: ${themeGet('primary.0', '#EE3932')};

                a {
                    color: ${themeGet('primary.0', '#EE3932')};
                }
            }

            &:focus,
            &:hover {
                border-color: ${themeGet('primary.0', '#EE3932')};

                a {
                    color: ${themeGet('primary.0', '#EE3932')};
                }
            }
        }
    }

    .ant-select-selection {
        background-color: green;
    }

    .ant-upload-list-item-info {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
    }


    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: hsl(0, 0%, 70%);
    }

    .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
        box-shadow: none;
    }

    .anticon-check-circle {
        color: ${themeGet('primary.0', '#EE3932')};
    }

    div.gmnoprint button.gm-control-active {
        img {
            height: 14px !important;
            width: 14px !important;
        }
    }

    .ant-picker {
        border: 1px solid #e6e6e6;
        border-radius: 3px;

        &:hover {
            border: 1px solid ${themeGet('text.1')};
        }
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: ${themeGet('primary.0', '#EE3932')};
    }

    // .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    //   background-color: ${themeGet('primary.0', '#EE3932')};
    // }

    .ant-picker-today-btn {
        color: ${themeGet('primary.0', '#EE3932')};
    }

    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
        background-color: ${themeGet('primary.2', '#eb8c8960')};
    }

    .save-btns {
        display: flex;
        justify-content: space-between;
        gap: 15px;

        @media only screen and (max-width: 400px) {
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        > button[type='submit'] {
            border-radius: 3px;
        }
    }

    .upload-cancel {
        .ant-btn :hover {
            span {
                color: #EE3932;
            }
        }
    }

    .DateRangePickerInput__withBorder {
        border-radius: 3px;
    }

    .DateRangePickerInput {
        &:hover {
            border-color: hsl(0, 0%, 70%);
        }
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: hsl(0, 0%, 70%);
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: hsl(0, 0%, 70%);
        box-shadow: none;
    }

    .ant-menu-item > .ant-badge a:hover {
        color: ${themeGet('text.0')};
    }

    .ant-select-selection-placeholder {
        align-self: center;
        color: #666666
    }

    .infinite-scroll-component {
        overflow-x: hidden !important;
    }

    .ant-upload-list.ant-upload-list-picture-card {
        text-align: center;
    }

    .ant-col-22 {
        max-width: unset;
    }

    .card {
        .has-error {
            .ant-upload-list-item.ant-upload-list-item-list-type-picture-card {
                border-color: ${themeGet('error.0')} !important;
                background-color: ${themeGet('error.1')};
            }

            .feedback {
                text-align: center;
            }
        }
    }

    .ant-spin-nested-loading > div > .ant-spin {
        position: fixed;
    }

    .signin-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        height: 57px;
        font-size: 17px;
        font-weight: 700;
        max-width: none;

        svg {
            width: 18px;
            height: 18px;
            margin-right: 2px;
        }

        &::after {
            content: none;
        }
    }

    .without-footer {
        .ant-modal-footer {
            display: none;
        }
    }

    .ant-spin-blur {
        height: 100vh;
    }

    .link.disabled {
        pointer-events: none;
    }

    .swal2-styled.swal2-confirm {
        background: #EE3932;
        border-color: #EE3932;
    }

    .swal2-styled button {
        height: 37px;
        padding: 0 20px;
    }

    .ant-btn {
        height: 37px;
        display: flex;

        &-primary {
            background: ${themeGet('primary.5')};
            border-color: ${themeGet('primary.5')};

            :hover {
                background: ${themeGet('primary.6')};
                border-color: ${themeGet('primary.6')};
            }

            &[disabled] {
                background-color: #f5f5f5 !important;
                border-color: #d9d9d9 !important;
            }
        }
    }

    .DateInput_input__focused {
        border-bottom: 0;
        border-bottom: 2px solid #ee3932 !important;
    }

    .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
        background: #EE3932 !important;
        border-color: #ee383268 !important;
    }

    .CalendarDay__selected_span {
        background: #eb534e !important;
        border-color: #e76662 !important;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: rgb(252, 191, 1, 0.2) !important;
    }

    .autocomplete-options {
        z-index: 100000;
    }

    .ant-picker-time-panel + .ant-picker-footer {
        display: none;
    }

    .DayPickerKeyboardShortcuts_show__bottomRight::before {
        border-right: 33px solid #ee3932 !important;
    }

    .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
        color: #ee3932 !important;
        background-color: #ee383252 !important;
        border-color: #ee3932 !important;
    }

    .rhap_skip-button {
        width: 28px;
        height: 35px
    }

    .rhap_main-controls {
        align-items: initial;
    }

    .ant-switch {
        background-color: ${themeGet('primary.3', '#fcbe06')};
    }

    .ant-switch-checked {
        background-color: ${themeGet('primary.0', '#EE3932')};
    }

    .ant-btn-primary > a:only-child {
        padding: 0 10px;
    }

    #downloadCSV {
        min-width: 200px;
    }

    .twitter-icon {
        margin-right: 10px;

        path {
            stroke: ${themeGet('color.8', '#55ADEE')};
        }
    }

    .fb-icon {
        margin-right: 10px;

        path {
            stroke: ${themeGet('color.7', '#3b5998')};
            fill: ${themeGet('color.7', '#3b5998')};
        }
    }

    .whatsapp-icon {
        margin-right: 10px;

        path {
            stroke: ${themeGet('color.9', '#25D366')};
            fill: ${themeGet('color.9', '#25D366')};
        }
    }

    .custom-item {
        align-items: center;

        svg {
            margin-right: 10px;
            height: 32px;
            width: 32px;
        }
    }

    .crop-modal {
        .ant-modal-body {
            padding: 35px;
        }
    }

    .ant-dropdown-menu-item {
        display: flex;
    }

    .ant-dropdown-menu-item-icon {
        display: flex;
        align-items: center;
    }

    .ant-dropdown-menu-item-icon > svg {
        margin-right: 0.625em;
    }

    .WPiframe {
        margin-top: 20px;
        min-height: 60vh;
        border: none;
    }

    .production-podcast-warning-modal {
        .ant-modal-body {
            padding: 5vh 4vh 3vh 4vh;
        }
    }

    .credits-checkbox-container {
        margin-bottom: 0 !important;
    }

    .mobile-only {
        @media screen and (min-width: 481px) {
            display: none;
        }
    }

    .mobile-hidden {
        @media screen and (max-width: 480px) {
            display: none;
        }
    }

    #pwa-prompt-description {
        color: #000 !important;
        font-weight: bolder;
    }

    .iOSPWA-steps * {
        color: #000 !important;
        font-weight: bold;
    }

    .hidden {
        display: none !important;
    }

    .visible {
        display: block !important;
    }
`;

export const LottieWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${themeGet('backgrounds.lottie', '#fff')};
`;

export interface OptionType {
  label: string;
  value: string;
  isDefault?: boolean;
}

const customStyles: StylesConfig<OptionType, false> = {
  placeholder: (provided) => ({
    ...provided,
    color: '#666666',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#666666',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.data.isDefault ? '#666666' : provided.color,
  }),
};

(Select as any).defaultProps = {
  ...Select.defaultProps,
  styles: customStyles,
};

export default GlobalStyle;

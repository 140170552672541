import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { history } from 'utils/history';
import { ROUTES } from 'constant';
import { handleError, setUserLanguage } from 'utils/helpers';
import * as ActionTypes from './Auth.actions';
import * as EventsActionTypes from 'modules/Events/Events.actions';
import * as Interfaces from './Auth.interfaces';
import Api from './Auth.api';
import EventsApi from 'modules/Events/Events.api';
import { DefaultLanguage, SupportedLanguages } from '../Global/Constants';

function* postAuthLogin({ payload }: Interfaces.PostAuthLoginAction) {
  try {
    const { data } = yield call(Api.postAuthLogin, payload.data);
    yield put(ActionTypes.postAuthLogin.success(data));
    localStorage.setItem('token', data.token);
    localStorage.setItem('refresh', data.refresh_token);
    const { data: user } = yield call(EventsApi.getUserData);
    yield put(EventsActionTypes.getUserData.success(user));
    localStorage.setItem('user', JSON.stringify(user));
    setUserLanguage(SupportedLanguages.find((l) => l.id === user.language) || DefaultLanguage);
    if (payload.redirectTo) {
      history.push(payload.redirectTo);
    } else {
      history.push(ROUTES.BASE.EVENTS);
    }
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.postAuthLogin.failure(e));
  }
}

function* postAuthLoginSaga() {
  yield takeLatest(ActionTypes.POST_AUTH_LOGIN, postAuthLogin);
}

function* postAuthRegister({ payload }: Interfaces.PostAuthRegisterAction) {
  try {
    const { data } = yield call(Api.postAuthRegister, payload.data);
    yield put(ActionTypes.postAuthRegister.success(data));
    history.push(ROUTES.AUTH.CONFIRM_BY_EMAIL);
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.postAuthRegister.failure(e));
  }
}

function* postAuthRegisterSaga() {
  yield takeLatest(ActionTypes.POST_AUTH_REGISTER, postAuthRegister);
}

function* postSocialLogin({ payload }: Interfaces.PostSocialAuthAction) {
  try {
    const { data } = yield call(Api.postSocialAuth, payload.data);
    yield put(ActionTypes.postSocialAuth.success(data));
    localStorage.setItem('token', data.token);
    localStorage.setItem('refresh', data.refresh_token);
    const { data: user } = yield call(EventsApi.getUserData);
    yield put(EventsActionTypes.getUserData.success(user));
    localStorage.setItem('user', JSON.stringify(user));
    history.push(ROUTES.BASE.EVENTS);
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.postSocialAuth.failure(e));
  }
}

function* postSocialAuthSaga() {
  yield takeLatest(ActionTypes.POST_SOCIAL_AUTH, postSocialLogin);
}

function* postEmailResetPassword({ payload }: Interfaces.PostEmailResetPasswordAction) {
  try {
    const { data } = yield call(Api.postEmailResetPassword, payload.data);
    yield put(ActionTypes.postEmailResetPassword.success(data));
    history.push(ROUTES.AUTH.LOGIN_PAGE);
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.postEmailResetPassword.failure(e));
  }
}

function* postEmailResetPasswordSaga() {
  yield takeLatest(ActionTypes.POST_EMAIL_RESET_PASSWORD, postEmailResetPassword);
}

function* postResetPassword({ payload }: Interfaces.PostResetPasswordAction) {
  try {
    const { data } = yield call(Api.postResetPassword, payload.data);
    yield put(ActionTypes.postResetPassword.success(data));
    history.push(ROUTES.BASE.EVENTS);
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.postResetPassword.failure(e));
  }
}

function* postResetPasswordSaga() {
  yield takeLatest(ActionTypes.POST_RESET_PASSWORD, postResetPassword);
}

function* getEmailActivate({ payload }: Interfaces.GetEmailActivateAction) {
  try {
    const { data } = yield call(Api.getEmailActivate, payload.data);
    yield put(ActionTypes.getEmailActivate.success(data));
    localStorage.setItem('token', data.token);
    localStorage.setItem('refresh', data.refresh_token);
    const { data: user } = yield call(EventsApi.getUserData);
    localStorage.setItem('user', JSON.stringify(user));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getEmailActivate.failure(e));
  }
}

function* getEmailActivateSaga() {
  yield takeLatest(ActionTypes.GET_EMAIL_ACTIVATE, getEmailActivate);
}

function* postTokenVerify({ payload }: Interfaces.PostTokenVerifyAction) {
  try {
    const { data } = yield call(Api.postTokenVerify, payload.data);
    yield put(ActionTypes.postTokenVerify.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.postTokenVerify.failure(e));
  }
}

function* postTokenVerifySaga() {
  yield takeLatest(ActionTypes.POST_TOKEN_VERIFY, postTokenVerify);
}

export const authSaga = function* rootSaga() {
  yield all([
    fork(postSocialAuthSaga),
    fork(postAuthLoginSaga),
    fork(postAuthRegisterSaga),
    fork(postEmailResetPasswordSaga),
    fork(postResetPasswordSaga),
    fork(getEmailActivateSaga),
    fork(postTokenVerifySaga),
  ]);
};

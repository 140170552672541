import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { getUserInfo } from '../../utils/helpers';
import { IMenuList, IRole } from '../../interfaces';

export const RenderMenuList = (list: IMenuList[]) => {
  const role: IRole = getUserInfo('role');
  return list.reduce((acum: any, { key, exact = false, to, title, icon, onClick = () => null, permissions }) => {
    if (permissions && !permissions.includes(role)) return acum;
    const isExternalLink = typeof to === 'object' && 'external_uri' in to;

    const node = (
      <Menu.Item key={key} icon={icon}>
        {isExternalLink ? (
          <a href={(to as { external_uri: string }).external_uri} target="_blank" rel="noreferrer">
            {title}
          </a>
        ) : (
          <NavLink exact={exact} to={to as string} onClick={onClick}>
            {title}
          </NavLink>
        )}
      </Menu.Item>
    );

    return [...acum, node];
  }, []);
};
